import 'jquery';
import 'slick-carousel';


jQuery(document).ready(($) => {
  $(document).ready(function () {

    if ($(window).width() < 1024) {
      $('.team-slider').slick({
        infinite: false,
        speed: 1000,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        easing: 'ease-in-out',
        dots: true,
        dotsClass: 'slick-dots',
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    } else {
      if ($('.team-slider.slick-initialized').length) {
        $('.team-slider.slick-initialized').unslick();
      }
    }
  });
});
